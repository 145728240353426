import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/DefaultLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`„Betrachte den Menschen als ein Bergwerk, reich an Edelsteinen von unschätzbarem Wert. Nur die Erziehung kann bewirken, dass es seine Schätze enthüllt und die Menschheit daraus Nutzen zu ziehen vermag.“`}</p>
      <cite>—Bahá’u’lláh</cite>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba5b123dc9da2e0fbb122a23c4910186/eea4a/kinderklasse.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABpl5p1wA//8QAGRAAAwADAAAAAAAAAAAAAAAAAAECERIh/9oACAEBAAEFAuCpb2lVWW3g/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAAQACEBEiYf/aAAgBAQAGPwJaGVbLA7DeR//EABsQAAMBAQADAAAAAAAAAAAAAAABESExUXGx/9oACAEBAAE/IUmT+sjWXnYejBF1a7yiTF3G6J4f/9oADAMBAAIAAwAAABAj/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EHZhD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQEBAAIDAAAAAAAAAAAAAAERACExQZHR/9oACAEBAAE/EGsQAtPExWeFUn6y42cCVH5iACHQymVco0KbmODv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "kinderklasse",
            "title": "kinderklasse",
            "src": "/static/ba5b123dc9da2e0fbb122a23c4910186/e5166/kinderklasse.jpg",
            "srcSet": ["/static/ba5b123dc9da2e0fbb122a23c4910186/f93b5/kinderklasse.jpg 300w", "/static/ba5b123dc9da2e0fbb122a23c4910186/b4294/kinderklasse.jpg 600w", "/static/ba5b123dc9da2e0fbb122a23c4910186/e5166/kinderklasse.jpg 1200w", "/static/ba5b123dc9da2e0fbb122a23c4910186/eea4a/kinderklasse.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Wöchentlich finden in Mannheim `}<a parentName="p" {...{
        "href": "https://www.bahai.de/gemeinsames-handeln/familie-und-kinder/kinderklasse/"
      }}>{`Kinderklassen`}</a>{` statt, die jedem Kind offen stehen. In diesen Klassen lernen die Kinder geistige Eigenschaften wie Gerechtigkeit, Liebe und Wahrhaftigkeit. Sie lernen kritisch zu denken und zu reflektieren. Das Ziel ist, geistige Gesetze auf das Leben des Einzelnen und das der Gesellschaft anzuwenden.`}</p>
    <p>{`Dazu hören die Kinder Geschichten und lernen Zitate wie z. B. `}<em parentName="p">{`„O Freund! Nur Rosen der Liebe pflanze in den Garten deines Herzens…“`}</em>{`. Die Gruppe singt gemeinsam, malt und spielt Kooperationsspiele.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      